import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"

import * as styles from '../../styles/pages/product.module.css'

import image_k10x from '../../images/product/menu/k10x.jpg'
import image_k10xm from '../../images/product/menu/k10x_mobile.jpg'


const ProductDryWallPage = () => (
  <Layout mobileTitle={true} activePath='product/drywall' pageTitle="For DryWall">
  	<Link to="/product/drywall/k10x" className={styles.productbanner}>
  		<img className={styles.productitem+" fullblock-desktop-only"} src={image_k10x} alt="K-101 K-103" />
  		<img className={styles.productitem+" fullblock-mobile-only"} src={image_k10xm} alt="K-101 K-103" />
  	</Link>
  </Layout>
)

export default ProductDryWallPage
